export const BRANCH_LIST = [
  {
    id: 1,
    name: "GÒ DẦU",
    lat: 10.795963012183451,
    lng: 106.6230910177766,
    location: "146e Gò Dầu, Phường Tân Quý, Quận Tân Phú, Tp Hồ Chí Minh",
    phone: "0789.4848.66",
    time: "Giờ mở cửa: 10:00 - 22:00",
  },
];
// 10.796019864166249, 106.6230678423296
// 10.795963012183451, 106.6230910177766;
