export const LIST_FOODS = [
  {
    title: "BÚN ĐẬU ĐẦY ẮP (3-4 người ăn)",
    thumbnail: "/images/data/food/thumb-bun-dau-day-ap.png",
    des: `Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, chả giò, chả cua, chả ốc, chả sụn, chả lụa chiên, cà pháo, rau thơm`,
    detail: "",
    price: "163.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Bún đậu no nê (2 người ăn)",
    thumbnail: "/images/data/food/thumb-bun-dau-no-ne-2ng.png",
    des: `Bún lá, đậu hũ, thịt heo luộc, nem chua rán, chả cốm, chả giò, chả lụa chiên, cà pháo, rau thơm`,
    detail: "",
    price: "93.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: true,
  },
  {
    title: "BÚN ĐẬU MẮM TÔM",
    thumbnail: "/images/data/food/thumb-bun-dau-mam-tom.png",
    des: `Bún lá, đậu hũ, thịt heo luộc, cà pháo, rau thơm`,
    detail: "",
    price: "37.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: true,
  },
];
