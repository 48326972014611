export const LIST_EX_FOODS = [
  {
    title: "Nem chua rán",
    thumbnail: "/images/data/ex_food/e1.png",
    des: ``,
    detail: "",
    price: "20.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Chả giò",
    thumbnail: "/images/data/ex_food/e2.png",
    des: ``,
    detail: "",
    price: "20.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "thịt luộc",
    thumbnail: "/images/data/ex_food/e3.png",
    des: ``,
    detail: "",
    price: "23.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Đậu hũ",
    thumbnail: "/images/data/ex_food/e4.png",
    des: ``,
    detail: "",
    price: "15.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Chả cốm",
    thumbnail: "/images/data/ex_food/e5.png",
    des: ``,
    detail: "",
    price: "20.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Chả ốc",
    thumbnail: "/images/data/ex_food/e6.png",
    des: ``,
    detail: "",
    price: "20.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Chả cua",
    thumbnail: "/images/data/ex_food/e10.png",
    des: ``,
    detail: "",
    price: "20.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Chả Lụa Chiên",
    thumbnail: "/images/data/ex_food/e11.png",
    des: ``,
    detail: "",
    price: "20.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Chả Sụn",
    thumbnail: "/images/data/ex_food/e12.png",
    des: ``,
    detail: "",
    price: "20.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Rau thơm",
    thumbnail: "/images/data/ex_food/e7.png",
    des: ``,
    detail: "",
    price: "5.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Bún lá",
    thumbnail: "/images/data/ex_food/e8.png",
    des: ``,
    detail: "",
    price: "10.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
  {
    title: "Cà Pháo Muôi",
    thumbnail: "/images/data/ex_food/e9.png",
    des: ``,
    detail: "",
    price: "5.000",
    img1: "",
    img2: "",
    img3: "",
    time: "",
    isHome: false,
  },
];
