export const LIST_DRINKS = [
  // {
  //   title: "trà đá",
  //   thumbnail: "/images/data/drink/d1.png",
  //   des: `
  //   `,
  //   detail: "",
  //   price: "Free",
  // },
  {
    title: "trà tắc",
    thumbnail: "/images/data/drink/d2.png",
    des: ``,
    detail: "",
    price: "15.000",
  },
  {
    title: "Nước mơ",
    thumbnail: "/images/data/drink/d3.png",
    des: ``,
    detail: "",
    price: "20.000",
  },
  {
    title: "Nước sấu",
    thumbnail: "/images/data/drink/d4.png",
    des: ``,
    detail: "",
    price: "20.000",
  },
  {
    title: "Pepsi",
    thumbnail: "/images/data/drink/d5.png",
    des: ``,
    detail: "",
    price: "18.000",
  },
  {
    title: "coca",
    thumbnail: "/images/data/drink/d6.png",
    des: ``,
    detail: "",
    price: "18.000",
  },
  {
    title: "SPRITE",
    thumbnail: "/images/data/drink/d7.png",
    des: ``,
    detail: "",
    price: "18.000",
  },
  {
    title: "nƯỚC SUỐI",
    thumbnail: "/images/data/drink/d8.png",
    des: ``,
    detail: "",
    price: "10.000",
  },
];
